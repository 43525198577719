<template>
  <div id="app" :class="$route.meta.design">
      <Header v-if="$route.name !== 'Home'"/>
      <router-view/>
      <Footer v-if="$route.name !== 'Home'"></Footer>
  </div>
</template>
<script>
import Footer from "@/components/Footer";
import Header from "@/components/Header";
export default {
  name: 'App',
  metaInfo: {
    title: 'Aloft Space - Malaysia Interior Designer',
    titleTemplate: '%s | Aloft Space - Malaysia Interior Designer'
  },
  components: {
    Header,
    Footer,
  },
  mounted () {
    console.log(this.$route)
  }
}
</script>

<style lang="scss">

</style>
