<template>
  <footer class="container">
    <div class="row">
      <div class="col-12" style="text-align: right; font-size: 20px; text-decoration-thickness: 1px">
        <router-link :to="{name: 'Home'}"
                     class="">
          Back
        </router-link>
      </div>
    </div>
    <div class="space"></div>
    <div class="row footerBottom">
      <div class="col-12 d-flex flex-column text-center text-md-start flex-md-row justify-content-md-between">
        <Socials/>
        <div class="mt-2 mt-sm-0">
          <p>
            Copyright &copy; 2023&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;All Rights Reserved
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import Socials from "@/components/Socials";
export default {
  name: 'Footer',
  components: {Socials},
}
</script>
