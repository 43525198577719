import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as VueGoogleMaps from 'vue2-google-maps'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueMeta from 'vue-meta'
import VueGallery from 'vue-gallery'
import EasySlider from 'vue-easy-slider'
import axios from "axios";

import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

Vue.use(EasySlider)
// use axios
Vue.prototype.axios = axios;

Vue.prototype.$hostname = process.env.VUE_APP_PUBLIC_STRAPI_API_URL
Vue.prototype.$token = process.env.VUE_APP_PUBLIC_STRAPI_API_TOKEN
Vue.component('VGallery', VueGallery)

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/sass/app.scss'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDwy6f862SYOGxPB3QQOBTSP54Y2uWHbhA'
  }})

Vue.use(VueMeta, {
  titleTemplate: '%s - Aloft Space',
})

Vue.config.productionTip = false

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
