<template>
  <div class="socials">
    <a href="https://www.facebook.com/aloftspacemy" target="_blank" class="d-inline-block"><img src="/images/icons/facebook.png" width="20"/></a>
    <a href="https://www.instagram.com/aloft_space/" target="_blank" class="d-inline-block"><img src="/images/icons/insta.png" width="20"/></a>
    <a href="https://wa.link/uchx1e" target="_blank" class="d-inline-block"><img src="/images/icons/Whatsapp2.svg" width="20"/></a>
    <a href="https://www.youtube.com/channel/UCOKzL4NYMGTlW0LnTeeEBgA" target="_blank" class="d-inline-block"><img src="/images/icons/youtube.svg" width="20"/></a>
  </div>
</template>
<script>
export default {
  name: 'Socials'
}
</script>
