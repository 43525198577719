<template>
  <div id="home"
       :style="{backgroundImage:'url(\''+ this.$hostname+ '/' + activeSlide.attributes.image.data.attributes.url +'\')'}">
    <div style="height: 100vh" class="container position-relative d-flex flex-column justify-content-center">
      <div id="featured" class="d-flex flex-column justify-content-center">
        <img src="/images/logos/logoblack.svg" id="logo"/>
        <img class="mt-4" src="/images/logos/logo-text-black.svg" id="textLogo"/>
        <div class="mt-2">
          <router-link :to="{name: 'Projects', params: {type: 'residential'}}"
                       class="btn btn-outline-primary">
            Explore
          </router-link>
        </div>
      </div>
      <div id="footer">
        <Socials/>
      </div>
      <ul class="slick-dots">
        <li v-for="(slide, index) in slides"
            @click="moveSlide(index)"
            :key="index"
            :class="{'slick-active': index === activeIndex}">
          <button>{{ index }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
// import projects from '../projects.json'

//import slides from '../homeSlides.json'
import Socials from "@/components/Socials";
export default {
  name: 'Home',
  components: {Socials},
  metaInfo: {
    title: 'Home'
  },
  data () {
    return {
      projects: [],
      loading: true,
      isMobile: false,
      slides: [],
      activeIndex: 0,
      images : []
    }
  },
  computed: {
    activeSlide () {
      // where slide is active when activeIndex index equal to slides index
      return this.slides[this.activeIndex]
    }
  },
  created () {
    this.isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
    this.axios.get(this.$hostname + '/api/home-sliders?populate=*', {
      headers: {
        'Authorization': 'Bearer ' + this.$token
      }
    }).then(response => {
      this.slides = response.data.data
    })
    // this.projects = projects
    // this.slides = slides
    // // push featured project based on number of slides required
    // for (let i = 0; i < this.slides; i++) {
    //   // get featured photo from projects photos
    //   this.images.push(this.projects[i].photos.filter(photo => photo.featured === true)[0])
    // }
    // window.addEventListener('resize', this.handleResize)
    // this.handleResize()
  },
  mounted() {
    setInterval(myMethod, 5000);
    let index = 0
    let me = this

    function myMethod( )
    {
      me.moveSlide(index)
      index++

      if (index === me.slides.length) {
        index = 0
      }
    }
  },
  methods: {
    moveSlide(index) {
      this.activeIndex = index
    }

  }
}
</script>
<style lang="scss">
</style>
