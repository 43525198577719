<template>
  <header id="header" class="container d-flex flex-row justify-content-between">
    <router-link :to="{name: 'Home'}">
      <img class="logo" src="/images/logos/aloft-logo-with-word.png" alt="Aloftspace"/>
    </router-link>
    <nav id="nav" class="navbar navbar-expand-lg">
      <button class="navbar-toggler"
              @click="togglePanel"
              type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <img src="/images/icons/burger.svg" alt="Menu" width="30">
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link class="nav-link" :to="{name: 'Home'}">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" :to="{name: 'Projects', params: {type: 'residential'}}">Projects</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" :to="{name: 'Services'}">Services</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" :to="{name: 'About'}">About</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" active-class="active" :to="{name: 'Contact'}" style="padding-bottom: 0" href="#">Contact</router-link>
          </li>
        </ul>
      </div>

      <div id="sideNav">
        <transition name="show">
          <div class="navPanel" v-show="panelVisible">
            <a href="#" class="closePanel"
               v-on:click="closePanel"><img src="/images/icons/x.svg" width="40"/>
            </a>
            <ul class="nav flex-column">
              <li class="nav-item">
                <a class="nav-link active" href="#" v-on:click="() => projectExpanded = !projectExpanded">Projects</a>
                <b-collapse id="navbar-toggle-collapse" is-nav ref="collapseProject">
                  <b-navbar-nav class="ml-auto">
                    <b-nav-item :to="{name: 'Projects', params: {type: 'residential'}}">Residential</b-nav-item>
                    <b-nav-item :to="{name: 'Projects', params: {type: 'commercial'}}">Commercial</b-nav-item>
                  </b-navbar-nav>
                </b-collapse>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'Services'}">Services</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'About'}">About</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{name: 'Contact'}" style="padding-bottom: 0" href="#">Contact</router-link>
              </li>
            </ul>
<!--            <ul class="contact">-->
<!--              <li><img src="/images/icons/mail-orange.svg" width="18"/>&nbsp;&nbsp;<a href="mailto:maggie@aloftspace.net">maggie@aloftspace.net</a></li>-->
<!--              <li><img src="/images/icons/phone-orange.svg" width="18"/>&nbsp;&nbsp;<a href="tel:60116960862">+60116960862</a></li>-->
<!--              <li>-->
<!--                <img src="/images/icons/home-orange.svg" style="margin-bottom: 5px" width="18"/>&nbsp;&nbsp;A45-20, <br/> United Point Residence, <br/>-->
<!--                Taman Segambut Aman, <br/>-->
<!--                52100 Kuala Lumpur, Malaysia.-->
<!--              </li>-->

<!--            </ul>-->
          </div>
        </transition>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  name: 'Header',
  data () {
    return {
      panelVisible: false,
      projectExpanded: false,
    }
  },
  watch: {
    projectExpanded () {
      this.$refs.collapseProject.toggle()
    },
    $route (){
      this.panelVisible = false
    }
  },
  methods: {
    closePanel () {
      this.panelVisible = false
    },
    togglePanel () {
      this.panelVisible = !this.panelVisible
    }
  }
}
</script>
